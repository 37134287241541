
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { customLinkFieldShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    columns: arrayOf(
      shape({
        // type: string(),
        id: string(),
        heading: oneOfType([string(), nullable()]),
        body: oneOfType([string(), nullable()]),
        buttonLink: arrayOf(shape(customLinkFieldShape).loose),
        links: arrayOf(shape(customLinkFieldShape).loose),
      }).loose
    ),
  },
  data() {
    return {
      columnOpen: null,
    };
  },
  computed: {
    filteredColumns() {
      return this.columns.filter((column) => column.type === 'column');
    },
  },
  methods: {
    toggleColumn(id) {
      this.columnOpen = this.columnOpen === id ? null : id;
    },
  },
};
