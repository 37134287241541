import { render, staticRenderFns } from "./HomepageColumns.vue?vue&type=template&id=783f95b7&scoped=true"
import script from "./HomepageColumns.vue?vue&type=script&lang=js"
export * from "./HomepageColumns.vue?vue&type=script&lang=js"
import style0 from "./HomepageColumns.vue?vue&type=style&index=0&id=783f95b7&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783f95b7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/src/components/atoms/Icon.vue').default,AtomsLink: require('/src/components/atoms/Link.vue').default,MoleculesButtonPill: require('/src/components/molecules/button/Pill.vue').default})
